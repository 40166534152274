.navbar-style {
    @apply flex gap-6;
}

.navbar-mobile-style {
    @apply transform top-0 left-0 w-64 py-2 px-5 text-sm md:text-md bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 flex flex-col gap-4;
}

.navbar-open {
    @apply translate-x-0;
}

.navbar-close {
    @apply -translate-x-full;
}
