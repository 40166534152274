@font-face {
	font-family: 'avenir light';
	src: local('Avenir Light'), local('Avenir-Light'),
		url('./assets/fonts/Avenir-Light.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'avenir heavy';
	src: local('Avenir Heavy'), local('Avenir-Heavy'),
		url('./assets/fonts/Avenir-Heavy.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'avenir regular';
	src: local('Avenir Regular'), local('Avenir-Regular'),
		url('./assets/fonts/Avenir-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'avenir medium';
	src: local('Avenir Medium'), local('Avenir-Medium'),
		url('./assets/fonts/Avenir-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'avenir book';
	src: local('Avenir Book'), local('Avenir-Book'),
		url('./assets/fonts/Avenir-Book.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'metropolis regular';
	src: local('Metropolis Regular'), local('Metropolis-Regular'),
	url('./assets/fonts/Metropolis-Regular.otf') format('truetype');
}

@font-face {
	font-family: 'metropolis medium';
	src: local('Metropolis Medium'), local('Metropolis-Medium'),
	url('./assets/fonts/Metropolis-Medium.otf') format('truetype');
}

@font-face {
	font-family: 'metropolis bold';
	src: local('Metropolis Bold'), local('Metropolis-Bold'),
	url('./assets/fonts/Metropolis-Bold.otf') format('truetype');
}

@tailwind base;
@tailwind components;

body {
	font-family: 'metropolis medium', sans-serif;
	scroll-behavior: smooth;
}

.collapse-title,
.collapse > input[type='checkbox'] {
	min-height: initial;
	padding: 0.6rem;
	margin: 0;
}

.btn {
	box-shadow: 0 4px 8px 0 rgb(34 41 47 / 10%);
	padding: 12px 10px !important;
	border-radius: 4px !important;
}

.card {
	box-shadow: 0 4px 8px 0 rgb(34 41 47 / 10%);
	border: 1px solid #e7e1e1;
}

#root {
	@apply h-full w-full;
}

input {
	border: none;
	outline: none;
}

input:focus,
input:hover {
	border: none;
	outline: none;
}
.toggle {
	border: 1px solid #e7e1e1;
}
.toggle:hover {
	border: 1px solid #e7e1e1;
}

[data-theme='mach'] .btn {
	padding: 1px 16px;
	@apply text-base h-fit;
}

@tailwind utilities;

.slick-slide {
	margin: 0;
	padding: 5px;
	margin-top: 8px;
}

.slick-slide div {
	outline: none;
	width: 94%;
	height: 100%;
	margin: 0;
}

/* react-data-table */
/* *::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}

*::-webkit-scrollbar-track {
	background: #c0c1c4;
}

*::-webkit-scrollbar-thumb {
	background-color: #c0c1c4;
	border-radius: 20px;
	border: 3px solid #c0c1c4;
} */

*::-webkit-search-cancel-button {
	cursor: pointer;
	background-color: red !important;
}

.background-color {
	background-color: rgba(46, 79, 107, 0.05);
}
