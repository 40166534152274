.sidebar-style {
    @apply w-[20em];
}

.sidebar-mobile-style {
    @apply transform top-0 right-0 w-64 py-2 px-2 bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30;
}

.sidebar-open {
    @apply translate-x-0;
}

.sidebar-close {
    @apply translate-x-full;
}
